import React, { useState } from "react"
import {
  CCol,
  CRow
} from "@coreui/react"
import { createPortal } from "react-dom"
import { FaAws } from "react-icons/fa";
import { SiMicrosoftazure } from "react-icons/si";
import { SiGooglecloud } from "react-icons/si";

import { SandboxTermsAndConditionModal } from "components/common/modal"
import { ServiceCard } from "../../commonComponent"
import SandboxRequestForm from "./RequestForms/SandboxRequestForm"

const serviceProviderConfig = {
  aws: {
    provider: "AWS",
    title: "Amazon Web Services",
    icon: <FaAws style={{ fontSize: '5rem' }}/>,
    active: true
  },
  azure: {
    provider: "AZURE",
    title: "Microsoft Azure",
    icon: <SiMicrosoftazure style={{ fontSize: '5rem' }}/>,
    active: true
  },
  gcp: {
    provider: "GCP",
    title: "Google Cloud Platform",
    icon: <SiGooglecloud style={{ fontSize: '5rem' }}/>,
    active: true
  }
}

export default function RequestServiceForm() {
  const [serviceProvider, setServiceProvider] = useState("");
  const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] = useState(false);
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = useState(false);

  const resetStates = () => {
    setServiceProvider("");
    setShowTermsAndConditionsModal(false);
    setIsTermsAndConditionsChecked(false);
  }

  const handleServiceProviderSelect = (value) => {
    setShowTermsAndConditionsModal(true);
    setServiceProvider(value);
  }

  if (!isTermsAndConditionsChecked) {
    return (
      <div className="mt-4 service-request-container">
        <CRow className="m-5 flex justify-content-center">
          <CCol md={12} lg={6} className="mt-4 ml-4">
            <p className="sub-heading mb-3">
              This page allows Deloitte NSE employees to request a
              Sandbox Account.
            </p>
            <p className="sub-heading mb-3 mt-2">
              The Account is designed to allow you to build and test new ideas
              and software. A key constraint is that neither Deloitte data nor
              client data shall be used in the Account. A number of security
              guardrails are built-in.
            </p>
            <p className="sub-heading mb-3">
              A Sandbox Account does not provide a route to production and
              should be considered disposable.
            </p>
            <p className="sub-heading mb-3">
              It is primarily designed for training, technical spikes and
              experimentation that do not require client data to be used.
            </p>

            <p className="sub-heading mb-3">
              Sandbox Accounts have a mandatory{" "}
              <strong>90-day lifecycle</strong>. After 90 days, the Sandbox
              Account will be automatically (and irrevocably) deleted.
            </p>
            <p className="sub-heading mb-3">
              Please note, <strong>a valid UK WBS code is required</strong> to
              request an <strong>AWS</strong> Sandbox Account. Azure and GCP can be requested by
              all practitioners across NSE.
            </p>
            <p className="sub-heading mb-3">
              For more information please{" "}
              <a
                href="https://ocdocs.deloitte.co.uk/sandboxes/"
                target="_blank"
                rel="noopener noreferrer"
              >
                click here
              </a>
            </p>
          </CCol>
          <CCol md={12} lg={4}>
            <p className="h5 mb-3 ml-3"> Please choose a cloud provider</p>
            <ServiceCard
              key={serviceProviderConfig.aws.provider}
              value={serviceProviderConfig.aws.provider}
              setState={handleServiceProviderSelect}
              title={serviceProviderConfig.aws.title}
              imageIcon={serviceProviderConfig.aws.icon}
              active={serviceProviderConfig.aws.active}
            />
            <ServiceCard
              key={serviceProviderConfig.azure.provider}
              value={serviceProviderConfig.azure.provider}
              setState={handleServiceProviderSelect}
              title={serviceProviderConfig.azure.title}
              imageIcon={serviceProviderConfig.azure.icon}
              active={serviceProviderConfig.azure.active}
            />
            <ServiceCard
              key={serviceProviderConfig.gcp.provider}
              value={serviceProviderConfig.gcp.provider}
              setState={handleServiceProviderSelect}
              title={serviceProviderConfig.gcp.title}
              imageIcon={serviceProviderConfig.gcp.icon}
              active={serviceProviderConfig.gcp.active}
            />
          </CCol>
        </CRow>
        {serviceProvider &&
          createPortal(
            <SandboxTermsAndConditionModal
              show={showTermsAndConditionsModal}
              setShow={setShowTermsAndConditionsModal}
              setSelect={setIsTermsAndConditionsChecked}
              resetStates={resetStates}
              serviceProvider={serviceProvider}
              subHeading={`${serviceProvider} Sandbox Request`}
            />,
            document.getElementById("terms-and-conditions-modal")
          )}
      </div>
    )
  } else {
    if (serviceProvider !== '') {
      return (
        <SandboxRequestForm
          serviceProvider={serviceProvider}
          resetParentStates={resetStates}
          isTermsAndConditionsChecked={isTermsAndConditionsChecked}
        />
      )
    }
  }
}

RequestServiceForm.propTypes = {}